// NB: Everything except core-js, so that it is the first code that's executed

// @ts-expect-error missing types
import "vite/modulepreload-polyfill";
import "@js-temporal/polyfill";
import "@ant-design/v5-patch-for-react-19";
import "./App.css";

import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { enableMapSet } from "immer";
import { Prism } from "prism-react-renderer";

globalThis.Prism = Prism;
// @ts-expect-error no types
await import("prismjs/components/prism-bash");
// @ts-expect-error no types
await import("prismjs/components/prism-c");
// @ts-expect-error no types
await import("prismjs/components/prism-csv");

await init({ data }).catch((e: unknown) => {
  console.error("Failed to init emoji-mart", e);
});

enableMapSet();
