import { useRef } from "react";

type AcceptableCallback =
  | ((...args: never[]) => void)
  | ((...args: never[]) => Promise<never>);

/**
 * Sometimes a callback is just a callback and should not cause hooks/components
 * downstream to re-render.
 *
 * A litmus test often is whether a callback is called `on...` like `onClick`.
 *
 * Functions passed to `useEvent` must return `void` (or be async) to
 * prevent accidental misuse of `useEvent` where `useCallback` should be used.
 *
 * Resources:
 * - https://react.dev/reference/react/experimental_useEffectEvent
 * - https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 * - https://github.com/reactjs/rfcs/pull/220
 */
export function useEffectEvent<T extends Function & AcceptableCallback>(
  callback: T,
): T {
  const callbackRef = useRef<T>(callback);
  callbackRef.current = callback;

  const stableRef = useRef<Function>(null);
  stableRef.current ??= (...args: Parameters<T>) => {
    return callbackRef.current(...args);
  };

  return stableRef.current as T;
}

/**
 * Variant of `useEvent` that allows non-void results.
 *
 * `useEvent` doesn't permit non-void results to not accidentally
 * use a `useEvent` where a `useCallback` should be used.
 *
 * If one is really sure, `useEventWithResult` is available, e.g.
 * to report whether the dispatching of the event was successful.
 */
export function useEventWithResult<T extends Function>(callback: T): T {
  // @ts-expect-error Our protection rightfully complains here
  return useEffectEvent(callback);
}
