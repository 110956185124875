import "core-js/actual";
import "react-scan";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { toast } from "react-hot-toast";
import { BootRipple } from "~/components/BootRipple";
import { sentryInit } from "~/utils/sentry";

sentryInit();

// NB: after Sentry, so that we capture any load errors
import "./globalInit";

const App = lazy(() => import("./App").then((m) => ({ default: m.App })));

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Failed to find root element '#root'");

createRoot(rootElement).render(
  <Suspense fallback={<BootRipple />}>
    <App />
  </Suspense>,
);

import.meta.hot?.on("vite:beforeUpdate", () => {
  toast.remove();
});

if (import.meta.env.DEV) {
  await import("jotai-devtools/styles.css");
}
