import { atomWithStorage } from "jotai/utils";

export const devToolsEnabledAtom = atomWithStorage(
  "devToolsEnabled",
  import.meta.env.DEV,
  undefined,
  {
    getOnInit: true,
  },
);
